import { DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import moment from "moment-timezone";
import { authAxios } from "../../constants/auth";

const ChangeDateDialog = (props: any) => {
  const { trans, setClose, getApiData, setNotify } = props;
  const [date, setDate] = useState<any>();

  const handleInputChange = (e: any) => {
    setDate(e.target?.value);
  }

  const handleSubmit = async () => {
    const newDate = new Date(date);

    newDate && await authAxios.put(`/transactions/${trans?.id}`, {created_at: newDate})
      .then((res) => {
        setClose();
        setNotify({
          isOpen: true,
          message: "Update Transactions Successfully",
          type: "success",
        });
        getApiData();
      })
  }

  return (
    <React.Fragment>
      <DialogTitle>{"Change Created Date"}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ m: 1, minWidth: 300 }}
          InputLabelProps={{ shrink: true }}
          type="date"
          label="Created At"
          variant="outlined"
          defaultValue={moment(trans?.created_at).format('YYYY-MM-DD') || ""}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="warning">
          Save
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default ChangeDateDialog;
