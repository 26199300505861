import { Dialog, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv';
import ReusableTable, { paginationData } from '../../components/reusable-table/reusable-table';
import { getAklDate, getTransactionRefunded, getTransactionStatus, initialPagiData } from '../../constants/constants';
import Notification from '../../components/notification/notification';
import CustomerDetailDialog from '../../components/customer-detail-dialog/customer-detail-dialog';
import ChangeDateDialog from '../../components/change-date-dialog/change-date-dialog';
import { authAxios, getDataSource } from '../../constants/auth';

const TransactionsPage = () => {
  const [transactionsData, setTransactionsData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<paginationData>(initialPagiData);
  const [currentId, setCurrentId] = useState<number>();
  const [currentTrans, setCurrentTrans] = useState<number>();
  const [detailOpen, setDetailOpen] = useState(false);
  const [changeDateOpen, setChangeDateOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<any>();
  const [startDateValue, setStartDateValue] = useState<any>();
  const [endDateValue, setEndDateValue] = useState<any>();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const tableHeader: any[] = [
    {id: 'id', numeric: false, label: 'Id', render: (data: any) => (<div>{data.row.id}</div>)},
    {id: 'customer_id', numeric: false, label: 'Donor Id', render: (data: any) => (<div>{data.row.customer_id}</div>)},
    {id: 'customer_name', numeric: false, label: 'Donor Name', render: (data: any) => (<div>{(data.row.customers?.first_name ? data.row.customers?.first_name : '') + ' ' + (data.row.customers?.last_name ? data.row.customers?.last_name : '')}</div>)},
    {id: 'windcave_id', numeric: false, label: 'Windcave Id', render: (data: any) => (<div>{data.row.windcave_id}</div>)},
    {id: 'amount', numeric: false, label: 'Amount', render: (data: any) => (<div>$ {data.row.amount.toFixed(2)}</div>)},
    {id: 'status', numeric: false, label: 'Status', render: (data: any) => (<div>{getTransactionStatus(data.row.status)}</div>)},
    {id: 'created_at', numeric: false, label: 'Created At', render: (data: any) => (<div>{data.row.created_at && getAklDate(data.row.created_at)}</div>)},
    {id: 'updated_at', numeric: false, label: 'Updated At', render: (data: any) => (<div>{data.row.updated_at && getAklDate(data.row.updated_at)}</div>)},
    {id: 'refunded', numeric: false, label: 'Refunded Status', render: (data: any) => (<div>{getTransactionRefunded(data.row.refunded)}</div>)},
    {id: 'refunded_at', numeric: false, label: 'Refunded At', render: (data: any) => (<div>{data.row.refunded_at && getAklDate(data.row.refunded_at)}</div>)},
    {id: 'refunded_user', numeric: false, label: 'Refunded By', render: (data: any) => (<div>{data.row.refunded_user}</div>)},
    {id: 'change_create', numeric: false, label: 'Change Date', render: (data: any) => (<button className="bg-default" onClick={() => onChangeDate(data.row)}>Change</button>)},
    {id: 'refunded_action', numeric: false, label: 'Refund Action', render: (data: any) => ((data.row.refunded === 1 || data.row.status === 0) ? <button className="button-disable" disabled>{ data.row.status === 0 ? 'Declined' : 'Refunded'}</button> : 
      <button className="bg-default" onClick={() => onRefundTransaction(data.row.id)}>Refund</button>)},
    {id: 'customer_details', numeric: false, label: 'Donor Details', render: (data: any) => (<button className="bg-default" onClick={() => onShowCustomer(data.row.customer_id)}>Details</button>)},
    {id: 'resend_email', numeric: false, label: 'Resend Email', render: (data: any) => (<button className="bg-default" onClick={() => onResendEmail(data.row)}>Resend</button>)},
  ];

  const csvHeaders: any = [
    {label: 'Transaction Id', key: 'id'},
    {label: 'Customer Id', key: 'customer_id'},
    {label: 'Windcave Id', key: 'windcave_id'},
    {label: 'Amount', key: 'amount'},
    {label: 'Status', key: 'status'},
    {label: 'Created At', key: 'created_at'},
    {label: 'Updated At', key: 'updated_at'},
    {label: 'Refunded', key: 'refunded'},
    {label: 'Refunded At', key: 'refunded_at'},
    {label: 'Refunded User', key: 'refunded_user'},
  ];

  const getApiData = async () => {
    try {
      const resData = await getDataSource('/transactions');
      const resAllData = await getDataSource('/alltransactions');
      setTransactionsData(resAllData.data);
      setFilteredData(resData.data);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    document.title = "Transactions | ARHT Dashboard";
    getApiData();
  }, []);

  const csvReport = {
    filename: 'transactions_' + new Date().toLocaleDateString(),
    headers: csvHeaders,
    data: transactionsData?.map((item: any) => {
      return {
        id: 'ARHT-' + item.id,
        customer_id: item.customer_id,
        windcave_id: item.windcave_id,
        amount: '$ ' + item.amount.toFixed(2),
        status: getTransactionStatus(item.status),
        created_at: getAklDate(item.created_at),
        updated_at: getAklDate(item.updated_at),
        refunded: getTransactionRefunded(item.refunded),
        refunded_at: item.refunded_at && getAklDate(item.refunded_at),
        refunded_user: item.refunded_user,
      };
    }),
  };

  const handleSearch = () => {
    getDataSource('/transactions', undefined, undefined, undefined, undefined, searchValue, undefined, undefined, startDateValue, endDateValue).then(res => {
      setFilteredData(res.data);
    });
  }

  const onDateFilter = () => {
    let startDate = (document.getElementById('start_date') as HTMLInputElement)?.value;
    let endDate = (document.getElementById('end_date') as HTMLInputElement)?.value;
    setStartDateValue(startDate);
    setEndDateValue(endDate);

    getDataSource('/transactions', undefined, undefined, undefined, undefined, searchValue, undefined, undefined, startDate, endDate).then(res => {
      setFilteredData(res.data);
    })
    // if (startDate && endDate) {
    //   setFilteredData({...transactionsData, data: transactionsData.data.filter((item: any) => 
    //     new Date(item.created_at).getTime() > new Date(startDate).getTime() && 
    //     new Date(item.created_at).getTime() < new Date(endDate).getTime())});
    // }
  }

  const onDateReset = () => {
    let startDate = (document.getElementById('start_date') as HTMLInputElement);
    let endDate = (document.getElementById('end_date') as HTMLInputElement);

    if (startDate.value || endDate.value) {
      startDate.value = '';
      endDate.value = '';
      getDataSource('/transactions').then(res => {
        setFilteredData(res.data);
      })
    }
  }

  const onRefundTransaction = async (id: any) => {
    await authAxios.post(`/transactions/refund/${id}`).then(res => {
      setNotify({
        isOpen: true,
        message: "Transaction Refund Successfully",
        type: "success",
      });
      getApiData();
    });
  }

  // const onPeriodicPayment = async () => {
  //   await authAxios.post(`/transactions/periodic`).then(res => {
  //     setNotify({
  //       isOpen: true,
  //       message: "Periodic Payment Successfully",
  //       type: "success",
  //     });
  //     getApiData();
  //   })
  // }

  const onChangeDate = (trans: any) => {
    setCurrentTrans(trans);
    setChangeDateOpen(true);
  }

  const onShowCustomer = (id: number) => {
    setCurrentId(id);
    setDetailOpen(true);
  }

  const onResendEmail = async (trans: any) => {
    await authAxios.post(`/transactions/receipt/${trans.id}`)
      .then(res => {
        setNotify({
          isOpen: true,
          message: "Resend Receipt Email Successfully",
          type: "success",
        });
      })
  }
  
  return (
    <div className='p-1'>
      <h1>Transactions</h1>
      <div className="flex content-between items-end toolbar">
        <div className="flex items-end mb-1">
          <div className="mr-2">
            <TextField label="Search for Transactions" size="small" onChange={(e: any) => setSearchValue(e.target?.value)} variant="outlined" sx={{ m: 0, minWidth: 300 }}/>
            <button className="bg-default ml-1" onClick={handleSearch}>Confirm</button>
          </div>
          <div className="mr-2">
            <TextField label="Start Date" size="small" id="start_date" type="date" variant="outlined" InputLabelProps={{shrink: true}}/>
          </div>
          <div className="mr-2">
            <TextField label="End Date" size="small" id="end_date" type="date" variant="outlined" InputLabelProps={{shrink: true}}/>
          </div>
          <button className="bg-default mr-1" onClick={onDateFilter}>Filter</button>
          <button className="bg-active" onClick={onDateReset}>Reset</button>
        </div>
        <div className="mb-1">
          {/* {getStorage(userKey).role === 1 && <button className="bg-default mr-2" onClick={onPeriodicPayment}>Periodic Payment</button>} */}
          <CSVLink {...csvReport}>
            <button className="bg-default">Export To CSV</button>
          </CSVLink>
        </div>
        
      </div>
      <ReusableTable url={'transactions'} header={tableHeader} tableDatas={filteredData} startDateValue={startDateValue} endDateValue={endDateValue}/>
      <Dialog maxWidth={false} open={changeDateOpen} onClose={() => {setChangeDateOpen(false)}}><ChangeDateDialog trans={currentTrans} setClose={() => setChangeDateOpen(false)} getApiData={getApiData} setNotify={setNotify}/></Dialog>
      <Dialog maxWidth={false} open={detailOpen} onClose={() => {setDetailOpen(false)}}><CustomerDetailDialog id={currentId} setNotify={setNotify} setDetailClose={() => {setDetailOpen(false); getApiData()}}/></Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default TransactionsPage