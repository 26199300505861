import React, { useEffect, useState } from 'react'
import ReusableTable, { paginationData } from '../../components/reusable-table/reusable-table';
import { authAxios, getDataSource } from '../../constants/auth';
import { getAklDate, getUserRole, initialPagiData } from '../../constants/constants';
import DeleteDialog from "../../components/delete-dialog/delete-dialog";
import { Dialog } from '@mui/material';
import EditUserDialog from '../../components/edit-user-dialog/edit-user-dialog';
import Notification from "../../components/notification/notification";

const UsersPage = () => {
  const [usersData, setUsersData] = useState<paginationData>(initialPagiData);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentId, setCurrentId] = useState<number>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const tableHeader: any = [
    {id: 'id', numeric: false, label: 'User Id', render: (data: any) => (<div>{data.row.id}</div>)},
    {id: 'email', numeric: false, label: 'Email', render: (data: any) => (<div>{data.row.email}</div>)},
    {id: 'role', numeric: false, label: 'User Role', render: (data: any) => (<div>{getUserRole(data.row.role)}</div>)},
    {id: 'created_at', numeric: false, label: 'Created At', render: (data: any) => (<div>{data.row.created_at && getAklDate(data.row.created_at)}</div>)},
    {id: 'updated_at', numeric: false, label: 'Updated At', render: (data: any) => (<div>{data.row.updated_at && getAklDate(data.row.updated_at)}</div>)},
    {id: 'edit', numeric: false, label: 'Edit User', render: (data: any) => (<button className="bg-default" onClick={() => onEditUser(data.row.id)}>Edit</button>)},
    // {id: 'change_password', numeric: false, label: 'Change Password', render: (data: any) => (<button className="bg-default" onClick={() => onEditUser(data.row.id)}>Change</button>)},
    {id: 'delete', numeric: false, label: 'Delete User', render: (data: any) => (<button className="bg-active" onClick={() => onDeleteUser(data.row.id)}>Delete</button>)},
  ];

  const getApiData = async () => {
    try {
      const resData = await getDataSource('/users');
      // const resData = await authAxios.get('/users');
      setUsersData(resData.data);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    document.title = "Users | ARHT Dashboard";
    getApiData();
  }, []);

  const onCreateUser = () => {
    setIsCreate(true);
    setCurrentUser(null);
    setEditOpen(true);
  }

  const onEditUser = (id: number) => {
    setIsCreate(false);
    setCurrentUser({...usersData, data: usersData.data.filter((item: any) => item.id === id)[0]});
    setEditOpen(true);
  }

  const onDeleteUser = (id: number) => {
    setCurrentId(id);
    setDeleteOpen(true);
  }
  
  return (
    <div className="p-1">
      <h1>Users</h1>
      <div>
        <button className='bg-default' onClick={onCreateUser}>Create User</button>
      </div>
      <ReusableTable url={'users'} header={tableHeader} tableDatas={usersData} />
      <Dialog maxWidth={'md'} open={editOpen} onClose={() => {setEditOpen(false)}}><EditUserDialog user={currentUser} setEditClose={() => {setEditOpen(false)}} getApiData={getApiData} setNotify={setNotify} isCreate={isCreate}/></Dialog>
      <DeleteDialog deleteProperty={"user"} id={currentId} open={deleteOpen} setOpen={setDeleteOpen} getApiData={getApiData} setNotify={setNotify}/>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default UsersPage