import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { authAxios } from "../../constants/auth";

const DeleteDialog = (props: any) => {
  const {deleteProperty, id, open, setOpen, getApiData, setNotify} = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await authAxios.delete(`/${deleteProperty}/${id}`)
    .then(res => {
        handleClose();
        setNotify({
          isOpen: true,
          message: `${deleteProperty} Deleted Successfully`,
          type: "error",
        });
        getApiData();
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
          Are you sure to delete this {deleteProperty === 'customer' ? 'donor' : deleteProperty}?
      </DialogTitle>
      <DialogContent>
          This action can't be reversed.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>Cancel</Button>
        <Button onClick={handleDelete} color="error">Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
