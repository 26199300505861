import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { authAxios } from "../../constants/auth";
import { userRole } from "../../constants/constants";

const EditUserDialog = (props: any) => {
  const { user, setEditClose, getApiData, setNotify, isCreate } = props;
  const [userEdit, setUserEdit] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [showPassword, setShowPassword] = useState<boolean>(false)

  useEffect(() => {
    setUserEdit(user);
  }, []);

  const validate = (fieldValues = userEdit) => {
    let temp = { ...errors };

    if (userEdit.email) {
      temp["email"] = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(
        fieldValues.email
      )
        ? null
        : "Please Input valid email.";
    }

    setErrors({ ...temp });
    if (fieldValues === userEdit) {
      return temp["email"] === null;
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = async (e: any) => {
    if (userEdit && validate()) {
      const dataToSubmit = isCreate ? {
        email: userEdit.email,
        role: 2,
        password: userEdit.password
      } : (userEdit.password && userEdit.password !== '' ? {
        email: userEdit.email,
        role: userEdit.role,
        password: userEdit.password
      } : {
        email: userEdit.email,
        role: userEdit.role
      });

      isCreate ? await authAxios.post(`/user`, dataToSubmit).then(res => {
        setEditClose();
        setNotify({
          isOpen: true,
          message: "Create User Successfully",
          type: "success",
        });
        getApiData();
      }) : await authAxios
        .put(`/user/${user?.id}`, dataToSubmit)
        .then((res) => {
          setEditClose();
          setNotify({
            isOpen: true,
            message: "Update User Details Successfully",
            type: "success",
          });
          getApiData();
        });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserEdit({ ...userEdit, [name]: value });
  };
  return (
    <React.Fragment>
      <DialogTitle>{(isCreate ? 'Create' : 'Edit') + ' User'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ m: 1, minWidth: 200 }}>
            <Grid item xs={12}>
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Email"
                name="email"
                variant="standard"
                error={!!errors?.email}
                value={userEdit?.email || ""}
                onChange={handleInputChange}
              />
              {!isCreate && 
                <FormControl style={{ marginTop: "0.6rem" }}>
                  <InputLabel htmlFor="user_role">User Role</InputLabel>
                  <Select
                    sx={{ m: 1, minWidth: 200 }}
                    labelId="user_role"
                    label="User Role"
                    name="role"
                    variant="standard"
                    value={userEdit?.role || 1}
                    onChange={handleInputChange}
                  >
                    {userRole.map((item: any) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={userEdit?.password || ""}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={setEditClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="warning">
          {isCreate ? 'Create User' : 'Save'}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default EditUserDialog;
