import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import CustomersPage from './pages/customers-page/customers-page';
import TransactionsPage from './pages/transactions-page/transactions-page';
import UsersPage from './pages/users-page/users-page';
import { authTokenKey, getStorage } from './constants/auth';
import LoginPage from './pages/login-page/login-page';
import SettingsPage from './pages/settings-page/settings-page';

function App() {

  const routes = [
    { id: 1, path: '/customers', exact: true, component: <CustomersPage /> },
    { id: 2, path: '/transactions', exact: true, component: <TransactionsPage /> },
    { id: 3, path: '/users', exact: true, component: <UsersPage /> },
    { id: 4, path: '/settings', exact: true, component: <SettingsPage /> },
  ];
  let isToken = getStorage(authTokenKey)
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/login' element={isToken ? <Navigate to="/customers" /> : <LoginPage />} />
        {
          routes.map((route: any) => (
            <Route key={route.id} path={route.path} element={isToken ? route.component : <Navigate to='/login' />} />
          ))}

        <Route path='*' element={isToken ? <Navigate to="/customers" /> : <LoginPage />} />

      </Routes>
    </Router>
  );
}

export default App;
