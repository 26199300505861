import axios from "axios";
import { apiUrl } from "../environment";

export const authTokenKey = "AUTHTOKENKEY";
export const userKey = 'USERKEY';

export const getStorage = (key: any) => {
  return JSON.parse(localStorage.getItem(key)!);
};

export const setStorage = (key: any, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorage = (key: any) => {
    localStorage.removeItem(key);
}

export const authHeader = {'token': getStorage(authTokenKey)};

export const authAxios =
  getStorage(authTokenKey) !== null
    ? axios.create({
        baseURL: apiUrl,
        headers: authHeader,
      })
    : axios.create({ baseURL: apiUrl });

export const getDataSource = (
  url: string, 
  limit: number = 30, 
  offset: number = 0, 
  sort_by: string = '', 
  order: 'asc' | 'desc' = 'asc', 
  search: string = '', 
  searchBy: string = 'first_name', 
  donation_type: any = '', 
  start_date: string = '', 
  end_date: string = '') => {
  return authAxios.get(url + 
    '?limit=' + limit + 
    '&offset=' + offset + 
    '&sort_by=' + sort_by + 
    '&order=' + order + 
    '&search=' + search + 
    '&searchBy=' + searchBy + 
    '&donation_type=' + donation_type + 
    '&start_date=' + start_date + 
    '&end_date=' + end_date);
}