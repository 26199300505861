import {
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { authAxios } from "../../constants/auth";

const UpdateAdminEmailDialog = (props: any) => {
  const { adminEmail, setClose, getApiData, setNotify } = props;
  const [emailEdit, setEmailEdit] = useState<any>({email: null});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setEmailEdit(adminEmail);
  }, []);

  const validate = (fieldValues = emailEdit) => {
    let temp = { ...errors };

    if (emailEdit.email) {
      temp["email"] = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(
        fieldValues.email
      ) ? null : "Please Input valid email.";
    }

    setErrors({ ...temp });
    if (fieldValues === emailEdit) {
      return temp["email"] === null;
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEmailEdit({ ...emailEdit, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    if (emailEdit && validate()) {
      await authAxios.put(`/adminemail/${adminEmail.id}`, emailEdit).then(res => {
        setClose();
        setNotify({
          isOpen: true,
          message: "Update Admin Email Successfully",
          type: "success",
        });
        getApiData();
      })
    }
  };

  return (
    <React.Fragment>
      <DialogTitle>Update Admin Email</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ m: 1, minWidth: 200 }}>
            <Grid item xs={12}>
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Email"
                name="email"
                variant="standard"
                error={!!errors?.email}
                value={emailEdit?.email || ""}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="warning" disabled={!emailEdit.email}>
          Save
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default UpdateAdminEmailDialog;
