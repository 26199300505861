import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  authAxios,
  authTokenKey,
  setStorage,
  userKey,
} from "../../constants/auth";
import Notification from "../../components/notification/notification";

const LoginPage = () => {
  const [user, setUser] = useState<any>();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    document.title = "Login | ARHT Dashboard";
  }, [])
  

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const onLogin = async () => {
    if (!(user.email && user.password)) {
      setNotify({
        isOpen: true,
        message: "Please input your email and password.",
        type: "error",
      });
    } else {
      await authAxios.post(`/login`, user).then((res: any) => {
        setStorage(authTokenKey, res.data.token?.token);
        setStorage(userKey, res.data.user);
        window.location.reload();
      }).catch(err => {
        setNotify({
          isOpen: true,
          message: "Please input correct email or password",
          type: "error",
        });
      });
    }
  };

  return (
    <div className="w-100 h-80 flex content-center items-center flex-column">
      <h1>Login</h1>
      <div>
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          onChange={handleInputChange}
          sx={{ m: 0, minWidth: 400 }}
        />
      </div>
      <div className="mt-1">
        <TextField
          label="Password"
          name="password"
          variant="outlined"
          type="password"
          onChange={handleInputChange}
          sx={{ m: 0, minWidth: 400 }}
        />
      </div>
      <button className="mt-1 mw-400 bg-active" onClick={onLogin}>
        Log In
      </button>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default LoginPage;
