import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { customerDonationType } from "../../constants/constants";
import moment from "moment-timezone";
import { authAxios } from "../../constants/auth";

const EditCustomerDialog = (props: any) => {
  const { customer, setEditClose, getApiData, setNotify } = props;
  const [customerEdit, setCustomerEdit] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setCustomerEdit(customer);
  }, []);

  const validate = (fieldValues = customerEdit) => {
    let temp = {...errors};
    
    if (customerEdit.email) {
      temp['email'] = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(fieldValues.email) ? null : 'Please Input valid email.'
    }

    setErrors({...temp});
    if (fieldValues === customerEdit) {
      return temp['email'] === null;
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCustomerEdit({ ...customerEdit, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    if (customerEdit && validate()) {
      const dataToSubmit = {
        id: customerEdit.id,
        first_name: customerEdit.first_name,
        last_name: customerEdit.last_name,
        email: customerEdit.email,
        phone_number: customerEdit.phone_number,
        amount: parseInt(customerEdit.amount),
        donation_type: customerEdit.donation_type,
        company: customerEdit.company,
        comments: customerEdit.comments,
        address1: customerEdit.address1,
        address2: customerEdit.address2,
        suburb: customerEdit.suburb,
        city: customerEdit.city,
        country: customerEdit.country,
        postcode: customerEdit.postcode,
        next_donation_date: customerEdit.next_donation_date
      };

      await authAxios
        .put(`/customer/${customer?.id}`, dataToSubmit)
        .then((res) => {
          setEditClose();
          setNotify({
            isOpen: true,
            message: "Update Customer Details Successfully",
            type: "success",
          });
          getApiData();
        });
    }
  };

  return (
    <React.Fragment>
      <DialogTitle>Edit Donor</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ m: 1, minWidth: 200 }}>
            <Grid item xs={4}>
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="First Name"
                name="first_name"
                variant="standard"
                value={customerEdit?.first_name || ""}
                onChange={handleInputChange}
              />
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Last Name"
                name="last_name"
                variant="standard"
                value={customerEdit?.last_name || ""}
                onChange={handleInputChange}
              />
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Phone Number"
                type="number"
                name="phone_number"
                variant="standard"
                value={customerEdit?.phone_number || ""}
                onChange={handleInputChange}
              />
              <TextField
                label="Email"
                name="email"
                variant="standard"
                error={!!errors?.email}
                sx={{ m: 1, minWidth: 200 }}
                value={customerEdit?.email || ""}
                onChange={handleInputChange}
              />
              <TextField
                label="Company"
                name="company"
                variant="standard"
                // error={!!errors?.email}
                sx={{ m: 1, minWidth: 200 }}
                value={customerEdit?.company || ""}
                onChange={handleInputChange}
              />
              <TextField
                label="Comments"
                name="comments"
                variant="standard"
                sx={{ m: 1, minWidth: 200 }}
                value={customerEdit?.comments || ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Address 1"
                name="address1"
                variant="standard"
                value={customerEdit?.address1 || ""}
                onChange={handleInputChange}
              />
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Address 2"
                name="address2"
                variant="standard"
                value={customerEdit?.address2 || ""}
                onChange={handleInputChange}
              />
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Suburb"
                name="suburb"
                variant="standard"
                value={customerEdit?.suburb || ""}
                onChange={handleInputChange}
              />
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="City"
                name="city"
                variant="standard"
                value={customerEdit?.city || ""}
                onChange={handleInputChange}
              />
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Country"
                name="country"
                variant="standard"
                value={customerEdit?.country || ""}
                onChange={handleInputChange}
              />
              <TextField
                sx={{ m: 1, minWidth: 200 }}
                label="Postcode"
                name="postcode"
                variant="standard"
                value={customerEdit?.postcode || ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              
              <FormControl style={{ marginTop: "0.6rem" }}>
                <InputLabel htmlFor="donation_type">Donation Type</InputLabel>
                <Select
                  labelId="donation_type"
                  variant="standard"
                  sx={{ m: 1, minWidth: 200 }}
                  name="donation_type"
                  value={customerEdit?.donation_type || 1}
                  onChange={handleInputChange}
                  label="Donation Type"
                >
                  {customerDonationType.map((item: any) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Amount"
                name="amount"
                type="number"
                variant="standard"
                sx={{ m: 1, minWidth: 200 }}
                value={customerEdit?.amount || ""}
                onChange={handleInputChange}
              />
              <TextField
                id="next_donation_date"
                InputLabelProps={{ shrink: true }}
                label="Next Donation Date"
                name="next_donation_date"
                type="date"
                variant="standard"
                sx={{ m: 1, minWidth: 200 }}
                value={moment(customerEdit?.next_donation_date).format('YYYY-MM-DD') || ""}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={setEditClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="warning">
          Save
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default EditCustomerDialog;
