import { Dialog } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { authTokenKey, getStorage, removeStorage, userKey } from '../../constants/auth'
import './navbar.css'
import ChangePasswordDialog from '../change-password-dialog/change-password-dialog'
import Notification from '../notification/notification'

const Navbar = () => {
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const handleCustomersActive = (e: any) => {
    const transactionsLink = document.getElementById('transactions-link');
    const usersLink = document.getElementById('users-link');
    const settingsLink = document.getElementById('settings-link');
    if (!e.target.classList?.contains('active')) {
      e.target.classList.add('active');
      transactionsLink?.classList.remove('active');
      usersLink?.classList.remove('active');
      settingsLink?.classList.remove('active');
    }
  }

  const handleTransactionsActive = (e: any) => {
    const customersLink = document.getElementById('customers-link');
    const usersLink = document.getElementById('users-link');
    const settingsLink = document.getElementById('settings-link');
    if (!e.target.classList?.contains('active')) {
      e.target.classList.add('active');
      customersLink?.classList.remove('active');
      usersLink?.classList.remove('active');
      settingsLink?.classList.remove('active');
    }
  }

  const handleUsersActive = (e: any) => {
    const customersLink = document.getElementById('customers-link');
    const transactionsLink = document.getElementById('transactions-link');
    const settingsLink = document.getElementById('settings-link');
    if (!e.target.classList?.contains('active')) {
      e.target.classList.add('active');
      customersLink?.classList.remove('active');
      transactionsLink?.classList.remove('active');
      settingsLink?.classList.remove('active');
    }
  }

  const handleSettingsActive = (e: any) => {
    const customersLink = document.getElementById('customers-link');
    const transactionsLink = document.getElementById('transactions-link');
    const usersLink = document.getElementById('users-link');
    if (!e.target.classList?.contains('active')) {
      e.target.classList.add('active');
      customersLink?.classList.remove('active');
      transactionsLink?.classList.remove('active');
      usersLink?.classList.remove('active');
    }
  }

  const onLogout = () => {
    removeStorage(authTokenKey);
    removeStorage(userKey);

    window.location.reload();

  }

  return (
    <div className="navbar flex content-between items-center p-1">
      <div className='flex content-start items-center'>
        <div className='fs-20 mr-2'><strong>ARHT</strong></div>
          {getStorage(authTokenKey) && (
            <>
              <Link id="customers-link" className={'mr-2 navbar-link' + (window.location.pathname === '/customers' ? ' active' : '')} to="customers" onClick={handleCustomersActive}>Donors</Link>
              <Link id="transactions-link" className={'mr-2 navbar-link' + (window.location.pathname === '/transactions' ? ' active' : '')} to="transactions" onClick={handleTransactionsActive}>Transactions</Link>
              {getStorage(userKey).role === 1 && <Link id="users-link" className={'mr-2 navbar-link' + (window.location.pathname === '/users' ? ' active' : '')} to="users" onClick={handleUsersActive}>Users</Link>}
            </>
          )}
      </div>
      {getStorage(authTokenKey) && 
        <div>
          <span className='mr-2'>{getStorage(userKey).email}</span>
          {getStorage(userKey).role === 1 && <Link id='settings-link' className={'mr-2 navbar-link' + (window.location.pathname === '/settings' ? ' active' : '')} to='settings' onClick={handleSettingsActive}>Settings</Link>}
          {getStorage(userKey).role === 2 && <span className='mr-2 navbar-link' onClick={() => setPasswordOpen(true)}>Change Password</span>}
          <Link className='navbar-link mr-3' to='login' onClick={onLogout}>Log Out</Link>
        </div>}
      <Dialog maxWidth={'md'} open={passwordOpen} onClose={() => {setPasswordOpen(false)}}><ChangePasswordDialog setClose={() => {setPasswordOpen(false)}} setNotify={setNotify}/></Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default Navbar