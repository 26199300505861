import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import { authAxios } from "../../constants/auth";

const ChangePasswordDialog = (props: any) => {
  const { setClose, setNotify } = props;
  const [userPassword, setUserPassword] = useState<any>({
    password: null,
    confirmPassword: null,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserPassword({ ...userPassword, [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async () => {
    if (userPassword.password !== userPassword.confirmPassword) {
      setNotify({
        isOpen: true,
        message: "Please enter same password",
        type: "error",
      });
    } else {
      await authAxios
        .put('/user-updatepassword', userPassword)
        .then((res) => {
          setClose();
          setNotify({
            isOpen: true,
            message: "Update Password Successfully",
            type: "success",
          });
        });
    }
  };

  return (
    <React.Fragment>
      <DialogTitle>{"Change Password"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ m: 1, minWidth: 200 }}>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={userPassword?.password || ""}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard">
                <InputLabel htmlFor="confirm-password">
                  Confirm Password
                </InputLabel>
                <Input
                  id="confirm-password"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={userPassword?.confirmPassword || ""}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="warning"
          disabled={!(userPassword.password && userPassword.confirmPassword)}
        >
          Save
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default ChangePasswordDialog;
