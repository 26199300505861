import { Dialog, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomerDetailDialog from "../../components/customer-detail-dialog/customer-detail-dialog";
import DeleteDialog from "../../components/delete-dialog/delete-dialog";
import EditCustomerDialog from "../../components/edit-customer-dialog/edit-customer-dialog";
import ReusableTable, { paginationData } from "../../components/reusable-table/reusable-table";
import { customerDonationType, getAklDate, getDonationType, initialPagiData } from "../../constants/constants";
import Notification from "../../components/notification/notification";
import { CSVLink } from "react-csv";
import { getDataSource } from "../../constants/auth";

const CustomersPage = () => {
  const [customersData, setCustomersData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<paginationData>(initialPagiData);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [currentId, setCurrentId] = useState<number>();
  const [currentCustomer, setCurrentCustomer] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>();
  const [typeValue, setTypeValue] = useState<any>();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const tableHeader: any[] = [
    {id: 'id', numeric: false, label: 'ID', render: (data: any) => (<div>{data.row.id}</div>)},
    {id: 'first_name', numeric: false, label: 'First Name', render: (data: any) => (<div>{data.row.first_name}</div>)},
    {id: 'last_name', numeric: false, label: 'Last Name', render: (data: any) => (<div>{data.row.last_name}</div>)},
    {id: 'phone_number', numeric: false, label: 'Phone Number', render: (data: any) => (<div>{data.row.phone_number}</div>)},
    {id: 'email', numeric: false, label: 'Email', render: (data: any) => (<div>{data.row.email}</div>)},
    {id: 'company', numeric: false, label: 'Company', render: (data: any) => (<div>{data.row.company}</div>)},
    {id: 'reference_number', numeric: false, label: 'Reference Number', render: (data: any) => (<div>{data.row.reference_number}</div>)},
    {id: 'optiin_newsletters', numeric: false, label: 'Opti-In To Newsletters', render: (data: any) => (<div>{data.row.optiin_newsletters ? 'True' : 'False'}</div>)},
    // {id: 'address1', numeric: false, label: 'Address 1', render: (data: any) => (<div>{data.row.address1}</div>)},
    // {id: 'address2', numeric: false, label: 'Address 2', render: (data: any) => (<div>{data.row.address2}</div>)},
    // {id: 'suburb', numeric: false, label: 'Suburb', render: (data: any) => (<div>{data.row.suburb}</div>)},
    {id: 'city', numeric: false, label: 'City', render: (data: any) => (<div>{data.row.city}</div>)},
    // {id: 'country', numeric: false, label: 'Country', render: (data: any) => (<div>{data.row.country}</div>)},
    // {id: 'postcode', numeric: false, label: 'Postcode', render: (data: any) => (<div>{data.row.postcode}</div>)},
    {id: 'recurring', numeric: false, label: 'Recurring Payment On', render: (data: any) => (<div>{data.row.donation_type === 1 ? 'Not Recurring' : 'Recurring'}</div>)},
    {id: 'donation_type', numeric: false, label: 'Donation Type', render: (data: any) => (<div>{getDonationType(data.row.donation_type)}</div>)},
    {id: 'amount', numeric: false, label: 'Amount', render: (data: any) => (<div>$ {data.row.amount ? data.row.amount.toFixed(2) : ''}</div>)},
    {id: 'last_transaction_amount', numeric: false, label: 'Last Trans Amount', render: (data: any) => (<div>$ {data.row.latest_transaction ? data.row.latest_transaction?.amount.toFixed(2) : 0}</div>)},
    {id: 'last_transaction_date', numeric: false, label: 'Last Trans Date', render: (data: any) => (<div>{data.row.latest_transaction ? getAklDate(data.row.latest_transaction?.created_at) : 'No trans'}</div>)},
    {id: 'next_donation_date', numeric: false, label: 'Next Donation Date', render: (data: any) => (<div>{data.row.next_donation_date ? getAklDate(data.row.next_donation_date) : 'No date'}</div>)},
    {id: 'comments', numeric: false, label: 'Comments', render: (data: any) => (<div>{data.row.comments}</div>)},
    {id: 'label', numeric: false, label: 'Compaign Name', render: (data: any) => (<div>{data.row.label}</div>)},
    {id: 'payment_method', numeric: false, label: 'Payment Method', render: (data: any) => (<div>{data.row.payment_method}</div>)},
    {id: 'card_type', numeric: false, label: 'Card Type', render: (data: any) => (<div>{data.row.card_type}</div>)},
    {id: 'card_holder', numeric: false, label: 'Card Holder', render: (data: any) => (<div>{data.row.card_holder}</div>)},
    // {id: 'card_number', numeric: false, label: 'Card Number', render: (data: any) => (<div>{data.row.card_number}</div>)},
    // {id: 'card_expiry', numeric: false, label: 'Card Expiry', render: (data: any) => (<div>{data.row.card_expiry}</div>)},
    // {id: 'windcave_token', numeric: false, label: 'Windcave Token', render: (data: any) => (<div>{data.row.windcave_token}</div>)},
    // {id: 'payment_gateway_transaction_id', numeric: false, label: 'Payment Gateway Transaction ID', render: (data: any) => (<div>{data.row.payment_gateway_transaction_id}</div>)},
    // {id: 'receipt_number_issued', numeric: false, label: 'Receipt Number Issued', render: (data: any) => (<div>{data.row.receipt_number_issued}</div>)},
    // {id: 'windcave_customer_id', numeric: false, label: 'Windcave Customer ID', render: (data: any) => (<div>{data.row.windcave_customer_id}</div>)},
    // {id: 'windcave_subscription_id', numeric: false, label: 'Windcave Subscription ID', render: (data: any) => (<div>{data.row.windcave_subscription_id}</div>)},
    {id: 'start_date', numeric: false, label: 'Start Date', render: (data: any) => (<div>{data.row.start_date && getAklDate(data.row.start_date)}</div>)},
    {id: 'created_at', numeric: false, label: 'Created At', render: (data: any) => (<div>{data.row.created_at && getAklDate(data.row.created_at)}</div>)},
    {id: 'updated_at', numeric: false, label: 'Updated At', render: (data: any) => (<div>{data.row.updated_at && getAklDate(data.row.updated_at)}</div>)}, 
    {id: 'details', numeric: false, label: 'Details', render: (data: any) => (<button className="bg-default" onClick={() => onShowCustomer(data.row.id)}>Details</button>)},
    {id: 'edit', numeric: false, label: 'Edit', render: (data: any) => (<button className="bg-default" onClick={() => onEditCustomer(data.row.id)}>Edit</button>)},
    // {id: 'delete', numeric: false, label: 'Delete', render: (data: any) => (<button className="bg-active" onClick={() => onDeleteCustomer(data.row.id)}>Delete</button>)},
  ];

  const csvHeaders: any[] = [
    {label: 'First Name', key: 'first_name'},
    {label: 'Last Name', key: 'last_name'},
    {label: 'Email Address', key: 'email'},
    {label: 'Phone Number', key: 'phone_number'},
    {label: 'Company', key: 'company'},
    {label: 'Reference Number', key: 'reference_number'},
    {label: 'Opti-In To Newsletters', key: 'optiin_newsletters'},
    {label: 'Address 1', key: 'address1'},
    {label: 'Address 2', key: 'address2'},
    {label: 'Suburb', key: 'suburb'},
    {label: 'City', key: 'city'},
    {label: 'Country', key: 'country'},
    {label: 'Postcode', key: 'postcode'},
    {label: 'Date', key: 'created_at'},
    {label: 'Amount', key: 'amount'},
    // {label: 'Last Transaction Amount', key: 'last_transaction_amount'},
    {label: 'Type', key: 'type'},
    {label: 'Frequency', key: 'frequency'},
    {label: 'Comments', key: 'comments'},
    {label: 'Compaign Name', key: 'label'},
    {label: 'Payment Method', key: 'payment_method'},
    {label: 'Card Type', key: 'card_type'},
    {label: 'Card Holder', key: 'card_holder'},
    {label: 'Card Number', key: 'card_number'},
    {label: 'Card Expiry', key: 'card_expiry'},
    // {label: 'Windcave Token', key: 'windcave_token'},
    {label: 'Payment Gateway Transaction ID', key: 'payment_gateway_transaction_id'},
    {label: 'Receipt Number Issued to Donor via Website', key: 'receipt_number_issued'},
    {label: 'Windcave Customer ID', key: 'windcave_customer_id'},
    {label: 'Windcave Subscription ID', key: 'windcave_subscription_id'},
    {label: 'Start Date', key: 'start_date'},
  ];

  const getApiData = async () => {
    try {
      const resData = await getDataSource('/customers');
      const resAllData = await getDataSource('/allcustomers');
      setCustomersData(resAllData.data);
      setFilteredData(resData.data);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    document.title = "Donors | ARHT Dashboard";
    getApiData();
  }, []);

  const csvReport = {
    filename: 'customers_' + new Date().toLocaleDateString(),
    headers: csvHeaders,
    data: customersData?.map((item: any) => {
      return {
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone_number: item.phone_number,
        company: item.company,
        reference_number: item.reference_number,
        optiin_newsletters: item.optiin_newsletters === 1 ? 'Yes' : 'No',
        address1: item.address1,
        address2: item.address2,
        suburb: item.suburb,
        city: item.city,
        country: item.country,
        postcode: item.postcode,
        created_at: getAklDate(item.created_at),
        amount: '$ ' + item.amount ? item.amount.toFixed(2) : '',
        // last_transaction_amount: item.latest_transaction ? '$ ' + item.latest_transaction?.amount.toFixed(2) : '$ ' + 0,
        type: item.donation_type === 1 ? 'One Off' : 'Recurring',
        frequency: item.donation_type === 1 ? '' : getDonationType(item.donation_type),
        comments: item.comments,
        label: item.label,
        payment_method: item.payment_method,
        card_type: item.card_type,
        card_holder: item.card_holder,
        card_number: item.card_number,
        card_expiry: item.card_expiry,
        // windcave_token: item.windcave_token,
        payment_gateway_transaction_id: item.payment_gateway_transaction_id,
        receipt_number_issued: item.receipt_number_issued,
        windcave_customer_id: item.windcave_customer_id,
        windcave_subscription_id: item.windcave_subscription_id,
        start_date: getAklDate(item.start_date),
      };
    }),
  };

  const onShowCustomer = (id: number) => {
    setCurrentId(id);
    setDetailOpen(true);
  }

  const onEditCustomer = (id: number) => {
    setCurrentCustomer({...customersData, data: customersData.data.filter((item: any) => item.id === id)[0]});
    setEditOpen(true);
  }

  const onDeleteCustomer = (id: number) => {
    setCurrentId(id);
    setDeleteOpen(true);
  }

  const handleSearch = () => {
    getDataSource('/customers', undefined, undefined, undefined, undefined, searchValue, undefined, typeValue).then(res => {
      setFilteredData(res.data);
    });
  }

  const handleTypeFilter = (e: any) => {
    const value = e.target?.value;
    getDataSource('/customers', undefined, undefined, undefined, undefined, searchValue, undefined, value).then(res => {
      setFilteredData(res.data);
    });
    setTypeValue(value);
  }

  return (
    <div className="p-1">
      <h1>Donors</h1>
      <div className="flex content-between items-end">
        <div className="mb-1">
          <TextField label="Search for Donors" size="small" onChange={(e: any) => setSearchValue(e.target?.value)} variant="outlined" sx={{ m: 0, minWidth: 300 }}/>
          <button className="bg-default ml-1" onClick={handleSearch}>Confirm</button>
          <Select variant="outlined" size="small" defaultValue={0} onChange={handleTypeFilter} sx={{ ml: 2, minWidth: 200 }}>
            <MenuItem value={0} key={0}>{"All"}</MenuItem>
            {customerDonationType.map((item: any) => (
              <MenuItem value={item.value} key={item.value}>
                {item.type}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="mb-1">
          <CSVLink {...csvReport}>
            <button className="bg-default">Export To CSV</button>
          </CSVLink>
        </div>
      </div>
      <ReusableTable url={'customers'} header={tableHeader} tableDatas={filteredData} searchValue={searchValue} typeValue={typeValue}
        />
      <DeleteDialog deleteProperty={"customer"} id={currentId} open={deleteOpen} setOpen={setDeleteOpen} getApiData={getApiData} setNotify={setNotify}/>
      <Dialog maxWidth={'md'} open={editOpen} onClose={() => {setEditOpen(false)}}><EditCustomerDialog customer={currentCustomer} setEditClose={() => {setEditOpen(false)}} getApiData={getApiData} setNotify={setNotify}/></Dialog>
      <Dialog maxWidth={false} open={detailOpen} onClose={() => {setDetailOpen(false)}}><CustomerDetailDialog id={currentId} setNotify={setNotify} setDetailClose={() => {setDetailOpen(false); getApiData()}}/></Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default CustomersPage;
