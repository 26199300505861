import { Button, Collapse, FormControlLabel, makeStyles, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { authAxios, getDataSource } from "../../constants/auth";
import { defaultPageSize, pageSizeOptions } from "../../constants/constants";
import './reusable-table.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// ReactGA.pageview(window.location.pathname + window.location.search);

const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order: any, orderBy: any) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array: any, comparator: any) => {
  const stabilizedThis = array?.map((el: any, index: number) => [el, index]);
  stabilizedThis?.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.filter((item: any) => item[0].id != 0) // id of the placeholder in event database
    .map((el: any) => el[0]);
}

const ReusableTableHead = (props: any) => {
  const { classes, order, orderBy, rowCount, onRequestSort, header } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {header &&
          header.length &&
          header.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              style={{padding: '8px'}}
              align={headCell.numeric ? 'right' : 'left'}
              // padding={"normal"}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'} */}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

ReusableTableHead.propTypes = {
  header: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const useStyles: any = makeStyles((theme: any) => ({
//   root: {
//     width: '100%',
//   },
//   paper: {
//     width: '100%',
//     marginBottom: theme.spacing(2),
//   },
//   table: {
//     minWidth: 750,
//   },
//   visuallyHidden: {
//     border: 0,
//     clip: 'rect(0 0 0 0)',
//     height: 1,
//     margin: -1,
//     overflow: 'hidden',
//     padding: 0,
//     position: 'absolute',
//     top: 20,
//     width: 1,
//   },
// }));
export interface TableInterface {
  url: string;
  tableDatas: paginationData;
  header: {}[];
  deleteCall?: (id: any) => any;
  editCall?: (id: any) => any;
  searchValue?: string;
  typeValue?: any;
  startDateValue?: string;
  endDateValue?: string;
}

export interface paginationData {
  data: any[];
  total: number;
}

const ReusableTable = ({
  url,
  tableDatas,
  header,
  deleteCall,
  editCall,
  searchValue,
  typeValue,
  startDateValue,
  endDateValue
}: TableInterface) => {
  // const classes = useStyles();
  let currentTableData = tableDatas;
  const [currentTableDataState, setCurrentTableDataState] = useState<paginationData>(tableDatas);
  const [order, setOrder] = useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const [inProgress,setInProgress] = useState(false);

  useEffect(() => {
  }, [])

  const getCurrentPageFirstIndex = () => {
    return 1 + page * rowsPerPage;
  }

  const onPrevPage = () => {
    setInProgress(true);
    getDataSource(`/${url}`, undefined, page - 1, orderBy, order, searchValue, undefined, typeValue, startDateValue, endDateValue).then(res => {
      setCurrentTableDataState(res.data);
      setInProgress(false);
    });
    page !== 0 && setPage(page - 1);
  }
  
  const onNextPage = () => {
    setInProgress(true);
    getDataSource(`/${url}`, undefined, page + 1, orderBy, order, searchValue, undefined, typeValue, startDateValue, endDateValue).then(res => {
      setCurrentTableDataState(res.data);
      setInProgress(false);
    });
    (getCurrentPageFirstIndex() + rowsPerPage - 1 < currentTableData.total) && setPage(page + 1);
  }

  const handleRequestSort = (event: any, property: any) => {
    setInProgress(true);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getDataSource(`/${url}`, undefined, page, property, (isAsc ? 'desc' : 'asc'), searchValue, undefined, typeValue, startDateValue, endDateValue).then(res => {
      setCurrentTableDataState(res.data);
      setInProgress(false);
    });
  };

  // const handleChangePage = (event: any, newPage: any) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: any) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleChangeDense = (event: any) => {
  //   setDense(event.target.checked);
  // };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, currentTableData.data?.length - page * rowsPerPage);

  return (
    <div className={''}>
      {currentTableData && (
        <Paper className={''}>
          <TableContainer>
            <Table
              className={''}
              aria-labelledby='tableTitle'
              size={dense ? 'small' : 'medium'}
              aria-label='enhanced table'>
              <ReusableTableHead
                header={header}
                classes={{}}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={currentTableData.data.length}
              />
              <TableBody>
                {currentTableDataState.data.length !== 0 ? 
                  currentTableDataState.data.map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        {header.map((data: any, index: number) => {
                          const Render = data.render;
                          return (
                            <TableCell key={index} align='left' style={{padding: '8px'}}>
                              <Render row={row} />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }) : 
                  currentTableData.data.map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        {header.map((data: any, index: number) => {
                          const Render = data.render;
                          return (
                            <TableCell key={index} align='left' style={{padding: '8px'}}>
                              <Render row={row} />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={pageSizeOptions}
            component="div"
            count={currentTableData.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <div className="pagination-div p-1">
            <div className="flex content-end items-center">
            <span className="mr-2">{getCurrentPageFirstIndex()}-
              {Math.min((getCurrentPageFirstIndex() + rowsPerPage - 1), currentTableData.total)} of {currentTableData.total}</span>
            <Button disabled={page === 0 || inProgress}>
              <ChevronLeftIcon onClick={onPrevPage}/>
            </Button>
            <Button disabled={(getCurrentPageFirstIndex() + rowsPerPage - 1) > currentTableData.total || inProgress}>
              <ChevronRightIcon  onClick={onNextPage}/>
            </Button>
            </div>
          </div>
        </Paper>
      )}
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label='Dense padding'
      /> */}
    </div>
  );
}

export default ReusableTable

// stableSort(currentTableData.data, getComparator(order, orderBy))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
