import { Dialog, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import UpdateAdminEmailDialog from "../../components/update-admin-email-dialog/update-admin-email-dialog";
import Notification from "../../components/notification/notification";
import ChangePasswordDialog from "../../components/change-password-dialog/change-password-dialog";
import { authAxios } from "../../constants/auth";

const SettingsPage = () => {
  const [adminEmailsData, setAdminEmailsData] = useState<any>([]);
  const [reoccuringPaymentData, setReoccuringPaymentData] = useState<any>([]);
  const [updateEmailOpen, setUpdateEmailOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [reoccuringPayment, setReoccuringPayment] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const getApiData = async () => {
    try {
      const emailData = await authAxios.get('/adminemails');
      const reoccurData = await authAxios.get('/reoccurpay');
      setAdminEmailsData(emailData.data);
      setReoccuringPaymentData(reoccurData.data);
      setReoccuringPayment(reoccurData.data.reoccur === 0 ? false: true);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    document.title = "Settings | ARHT Dashboard";
    getApiData();
  }, []);

  const onUpdateEmail = () => {
    setUpdateEmailOpen(true);
  }

  const onChangePassword = () => {
    setPasswordOpen(true);
  }

  const handleReoccuringPayment = async () => {
    setInProgress(true);
    setReoccuringPayment(!reoccuringPayment);
    const data = {reoccur: reoccuringPaymentData.reoccur === 1 ? false : true};
    await authAxios.put(`/reoccurpay/${reoccuringPaymentData.id}`, data).then(res => {
      setInProgress(false)
      setNotify({
        isOpen: true,
        message: "Reoccuring Payment Change Successfully",
        type: "success",
      });
      getApiData();
    })
  }

  return (
    <div className="p-1">
      <h1>Settings</h1>
      <div className="mt-1 flex flex-column items-start">
        <div className="flex items-center">
          <h4 className="mr-1">Notification Email: </h4>
          <span>{adminEmailsData[0]?.email}</span>
        </div>
        <div className="flex mt-1">
          <button className="bg-default mr-2" onClick={onUpdateEmail}>Update Email</button>
          <button className="bg-active" onClick={onChangePassword}>Forgot Password?</button>
        </div>
        <div className="flex mt-1">
          <h4 className="mr-1">Reoccuring Payment Switch: </h4>
          <FormControlLabel control={<Switch disabled={inProgress} checked={reoccuringPayment} onChange={handleReoccuringPayment} />} 
            label={reoccuringPayment ? 'Reoccuring Opened' : 'Reoccuring Closed'} />
        </div>
      </div>
      <Dialog maxWidth={"md"} open={updateEmailOpen} onClose={() => setUpdateEmailOpen(false)}>
        <UpdateAdminEmailDialog adminEmail={adminEmailsData[0]} setClose={() => setUpdateEmailOpen(false)} getApiData={getApiData} setNotify={setNotify} />
      </Dialog>
      <Dialog maxWidth={'md'} open={passwordOpen} onClose={() => {setPasswordOpen(false)}}><ChangePasswordDialog setClose={() => {setPasswordOpen(false)}} setNotify={setNotify}/></Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default SettingsPage;
