import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAklDate, getDonationType, getTransactionRefunded, getTransactionStatus } from "../../constants/constants";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { authAxios } from "../../constants/auth";

const CustomerDetailDialog = (props: any) => {
  const { id, setNotify, setDetailClose } = props;
  const [customerData, setCustomerData] = useState<any>();
  const [showTrans, setShowTrans] = useState(false);
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const getApiData = async () => {
    try {
      const resData = await authAxios.get(`/customer/${id}`);
      setCustomerData(resData.data);
      setIsRecurring(resData.data?.donation_type === 1 ? false : true);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    getApiData();
  }, [])

  const handleShowTrans = () => {
    setShowTrans(!showTrans);
  }

  const handleCustomerReccuring = async () => {
    setInProgress(true);
    setIsRecurring(!isRecurring);
    const data = {donation_type: customerData?.donation_type === 1 ? 4 : 1};
    await authAxios
      .put(`/customer/${customerData?.id}`, data)
      .then((res) => {
        setInProgress(false);
        setNotify({
          isOpen: true,
          message: "Customer Recurring Change Successfully",
          type: "success",
        });
        getApiData();
      })
  }
  
  return (
    <React.Fragment>
      <DialogTitle>Donor Details</DialogTitle>
      <DialogContent>
          <div className="flex">
            <div className="p-1 mr-2">
                <p><strong className="mr-1">Donor Id: </strong><span>{customerData?.id}</span></p>
                <p><strong className="mr-1">Donor First Name: </strong><span>{customerData?.first_name}</span></p>
                <p><strong className="mr-1">Donor Last Name: </strong><span>{customerData?.last_name}</span></p>
                <p><strong className="mr-1">Donor Phone Number: </strong><span>{customerData?.phone_number}</span></p>
                <p><strong className="mr-1">Donor Email: </strong><span>{customerData?.email}</span></p>
                <p><strong className="mr-1">Donor Company: </strong><span>{customerData?.company}</span></p>
            </div>
            <div className="p-1 mr-2">
                <p><strong className="mr-1">Donor Donation Type: </strong><span>{getDonationType(customerData?.donation_type)}</span></p>
                <p><strong className="mr-1">Donor Reference Num: </strong><span>{customerData?.reference_number}</span></p>
                <p><strong className="mr-1">Donor Amount: </strong><span>$ {customerData && customerData?.amount ? customerData?.amount.toFixed(2) : 0}</span></p>
                <p><strong className="mr-1">Donor Comments: </strong><span>{customerData?.comments}</span></p>
                <p><strong className="mr-1">Opti-In To Newsletters: </strong><span>{customerData?.optiin_newsletters}</span></p>
                <p><strong className="mr-1">Donor Start Date: </strong><span>{customerData?.start_date && getAklDate(customerData?.start_date)}</span></p>
                
            </div>
            <div className="p-1 mr-2">
                <p><strong className="mr-1">Donor Address1: </strong><span>{customerData?.address1}</span></p>
                <p><strong className="mr-1">Donor Address2: </strong><span>{customerData?.address2}</span></p>
                <p><strong className="mr-1">Donor Suburb: </strong><span>{customerData?.suburb}</span></p>
                <p><strong className="mr-1">Donor City: </strong><span>{customerData?.city}</span></p>
                <p><strong className="mr-1">Donor Country: </strong><span>{customerData?.country}</span></p>
                <p><strong className="mr-1">Donor Postcode: </strong><span>{customerData?.postcode}</span></p>
            </div>
            <div className="p-1 mr-2">
                <p><strong className="mr-1">Payment Method: </strong><span>{customerData?.payment_method}</span></p>
                <p><strong className="mr-1">Donor Card Type: </strong><span>{customerData?.card_type}</span></p>
                <p><strong className="mr-1">Donor Card Holder: </strong><span>{customerData?.card_holder}</span></p>
                <p><strong className="mr-1">Donor Card Num: </strong><span>{customerData?.card_number}</span></p>
                <p><strong className="mr-1">Donor Card Expiry: </strong><span>{customerData?.card_expiry}</span></p>
                <p><strong className="mr-1">Windcave Token: </strong><span>{customerData?.windcave_token}</span></p>
            </div>
            <div className="p-1 mr-2">
                <p><strong className="mr-1">Payment Gateway Trans Id: </strong><span>{customerData?.payment_gateway_transaction_id}</span></p>
                <p><strong className="mr-1">Receipt Number Issued: </strong><span>{customerData?.receipt_number_issued}</span></p>
                <p><strong className="mr-1">Windcave Customer Id: </strong><span>{customerData?.windcave_customer_id}</span></p>
                <p><strong className="mr-1">Windcave Subscription Id: </strong><span>{customerData?.windcave_subscription_id}</span></p>
                <p><strong className="mr-1">Donor Created At: </strong><span>{customerData?.created_at && getAklDate(customerData?.created_at)}</span></p>
                <p><strong className="mr-1">Donor Updated At: </strong><span>{customerData?.updated_at && getAklDate(customerData?.updated_at)}</span></p>
            </div>
          </div>
          <div className="p-1 flex items-center">
            <strong className="mr-1">Reoccuring Payment Switch: </strong>
            <FormControlLabel control={<Switch disabled={inProgress} checked={isRecurring} onChange={handleCustomerReccuring} />} 
              label={isRecurring ? 'Reccuring' : 'Not Reccuring'} />
          </div>
          <div className="p-1 flex items-center">
            <strong className="mr-1" onClick={handleShowTrans}>Show Transactions</strong>
            <span>
              {showTrans ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </span>
          </div>
          {showTrans && customerData.transactions && (
            <div className="p-1">
              {customerData.transactions.map((item: any) => (
                <div key={item.id} className="flex mb-1">
                  <div className="mr-2"><strong className="mr-1">Id: </strong><span>{item?.id}</span></div>
                  <div className="mr-2"><strong className="mr-1">Amount: </strong><span>$ {item?.amount.toFixed(2)}</span></div>
                  <div className="mr-2"><strong className="mr-1">Windcave Id: </strong><span>{item?.windcave_id}</span></div>
                  <div className="mr-2"><strong className="mr-1">Transaction At: </strong><span>{item?.created_at && getAklDate(item?.created_at)}</span></div>
                  <div className="mr-2"><strong className="mr-1">Status: </strong><span>{getTransactionStatus(item?.status)}</span></div>
                  <div className="mr-2"><strong className="mr-1">Refund: </strong><span>{getTransactionRefunded(item?.refunded)}</span></div>
                  {item?.refunded === 1 && (
                    <>
                      <div className="mr-2"><strong className="mr-1">Refund At: </strong><span>{getAklDate(item?.refunded_at)}</span></div>
                      <div className="mr-2"><strong className="mr-1">Refund User: </strong><span>{item?.refunded_user}</span></div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={setDetailClose}>Close</Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default CustomerDetailDialog;
